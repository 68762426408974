<template>
  <div id="campaignOverviewPage">
    <!-- Header -->
    <div class="bg-white pt-3 px-4 pb-0 border-b headerSection">
      <el-row>
        <el-col :span="12">
          <div class="flex-1 min-w-0 pt-2">
            <h2 class="text-3xl font-bold leading-7 text-gray-900">Campaign Overview</h2>
          </div>
          <div class="md:flex md:items-center md:justify-between">
            <div class="flex tabsContainer">
              <div class="mr-4 p-2 px-3 border-b border-b-7 tabItem" @click="onTabChange('channel')" :class="{ activeTabItem: selectedTab == 'channel' }">Channel Summary</div>
              <!-- <div class="mr-4 p-2 px-2 border-b border-b-7 tabItem" @click="onTabChange('journey')" :class="{ activeTabItem: selectedTab == 'journey' }">Journey Summary</div> -->
              <div class="mr-0 p-2 px-3 border-b border-b-7 tabItem" @click="onTabChange('campaign')" :class="{ activeTabItem: selectedTab == 'campaign' }">Campaign Summary</div>
            </div>
          </div>
        </el-col>
        <el-col :span="12" class="text-right pt-3 flex align-right">
          <div class="flex-1">
            <el-select v-model="timingFilter" size="small" class="w-48">
              <el-option-group v-for="group in campaignOverviewTimingOptions" :key="group.label" :label="group.label">
                <el-option v-for="item in group.options" :key="item.value" :label="item.title" :value="item.value"> </el-option>
              </el-option-group>
            </el-select>
            <el-date-picker class="w-20 mt-2" size="small" :editable="false" start-placeholder="Start Date" end-placeholder="End Date" :clearable="false" v-if="timingFilter == 'custom'" v-model="customTimeRange" type="daterange" format="dd-MMM-yyyy" @change="fetchAllData(false)"> </el-date-picker>
            <div v-if="displayTimeRange && timingFilter != 'custom' && !fetchingOverview" class="text-right text-xs text-gray-400 py-1">
              {{ displayTimeRange.start + ' - ' + displayTimeRange.end }}
            </div>
          </div>
          <div class="pl-2">
            <el-tooltip class="item" effect="dark" content="Export Report" placement="top-start">
              <el-button @click="exportReport" type="info" size="small" icon="el-icon-download" plain circle></el-button>
            </el-tooltip>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- Channel Wise Summary Tab -->
    <div class="p-4" v-if="selectedTab == 'channel'">
      <!-- Daily Overview  -->
      <div class="overflow-hidden border-gray-400 rounded-md mb-4" style="border: 1px solid #dcdfe6ba">
        <div class="min-w-full divide-y divide-gray-200">
          <div>
            <div style="background: #f5f7fa !important" class="px-6 py-2 pr-3 leading-8 text-left text-md text-gray-700 tracking-wider">
              Daily Campaign Messages Sent
              <div class="text-gray-400 text-sm leading-8" style="float: right">Click on channel name to apply filter.</div>
            </div>
          </div>

          <div class="bg-white divide-y divide-gray-200">
            <div v-if="fetchingOverviewGraph">
              <div class="loadingDiv" v-loading="true" style="height: 300px; width: 100%"></div>
            </div>
            <template v-else>
              <div v-if="channelDataDaily && channelDataDaily.data && Object.keys(channelDataDaily.data).length > 0" class="mr-4 ml-2 mt-4">
                <DynamicTimelineGraphComponent :hidePagination="true" v-bind:graph="channelDataDaily"></DynamicTimelineGraphComponent>
              </div>
              <div class="h-40 pt-20 items-center text-center w-full text-sm text-gray-400" v-else>No Data Available</div>
            </template>
          </div>
        </div>
      </div>

      <!-- Overview  -->
      <div class="overflow-hidden border-gray-400 rounded-md mb-6" style="border: 1px solid #dcdfe6ba">
        <table class="min-w-full divide-y divide-gray-200">
          <thead style="background: #f5f7fa !important">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Campaign Channel</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Sent</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Failed</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Opened</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Clicked</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Converted</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Revenue</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-if="fetchingOverview">
              <td colspan="7" class="loadingDiv" v-loading="true" style="height: 300px; width: 100%"></td>
            </tr>
            <template v-else>
              <tr v-for="(row, index) in channelData" :key="index">
                <td class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ row.channel }}
                </td>
                <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                  {{ getNumberWithComma(row.sent) }}
                  <div>&nbsp;</div>
                </td>
                <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-400" style="font-size: 12px">
                  <div class="text-gray-500" style="font-size: 14px">{{ getNumberWithComma(row.failed) }}</div>
                  {{ getNumberWithCommaAndDecimal(row.failed_perc) }}%
                </td>
                <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-400" style="font-size: 12px">
                  <template v-if="row.rawChannel == 'email'">
                    <div class="text-gray-500" style="font-size: 14px">{{ getNumberWithComma(row.opened) }}</div>
                    {{ getNumberWithCommaAndDecimal(row.opened_perc) }}%
                  </template>
                  <span v-else
                    >N/A
                    <div>&nbsp;</div></span
                  >
                </td>

                <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-400" style="font-size: 12px">
                  <div class="text-gray-500" style="font-size: 14px">{{ getNumberWithComma(row.clicked) }}</div>
                  {{ getNumberWithCommaAndDecimal(row.clicked_perc) }}%
                </td>

                <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-400" style="font-size: 12px">
                  <div class="text-gray-500" style="font-size: 14px">{{ getNumberWithComma(row.converted) }}</div>
                  {{ getNumberWithCommaAndDecimal(row.converted_perc) }}%
                </td>

                <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                  {{ $store.state.token.currency }}&nbsp;{{ getNumberWithCommaAndDecimal(row.revenue) }}
                  <div>&nbsp;</div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Campaign Wise Summary Tab -->
    <div class="p-4 campaignSummaryTab" v-else>
      <!-- Table Filters  -->
      <div class="display-block h-10">
        <h2 class="float-left text-lg pt-1">{{ campaignTableData ? campaignTableData.length : '' }} Campaigns</h2>
        <div class="float-right">
          <el-select size="small" v-model="campaignTableFilters.channel" multiple collapse-tags placeholder="Campaign Channel">
            <el-option v-for="item in campaignTableFilterOptions.channel" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select class="statusFilter" size="small" v-model="campaignTableFilters.status" multiple collapse-tags placeholder="Campaign Status">
            <el-option v-for="item in campaignTableFilterOptions.status" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select class="campaignType" size="small" v-model="campaignTableFilters.type" multiple collapse-tags placeholder="Campaign Type">
            <el-option v-for="item in campaignTableFilterOptions.type" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-button type="primary" size="small" @click="fetchAllData" :disabled="fetchingOverview || fetchingOverviewGraph"> Apply Filters </el-button>
        </div>
      </div>

      <!-- Overview  -->
      <div class="overflow-hidden border-gray-400 rounded-md mb-6" style="border: 1px solid #dcdfe6ba">
        <table class="min-w-full divide-y divide-gray-200">
          <thead style="background: #f5f7fa !important">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Campaign</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Sent</th>
              <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Delivered</th> -->
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Failed</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Opened</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Clicked</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Converted</th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">Revenue</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-if="fetchingCampaignStats">
              <td colspan="7" class="loadingDiv" v-loading="true" style="height: 300px; width: 100%"></td>
            </tr>
            <template v-else>
              <tr v-if="campaignTableData.length == 0">
                <td colspan="7" style="height: 300px; width: 100%; text-align: center">
                  <span class="text-gray-400">No Data Found.</span>
                </td>
              </tr>
              <template v-else>
                <tr v-for="(row, index) in campaignTableData" :key="index">
                  <td class="px-6 pt-2 pb-2 whitespace-nowrap text-sm text-gray-900" style="max-width: 300px; overflow: hidden; text-overflow: ellipsis">
                    <template v-if="row.name">
                      <router-link :to="'/campaign/report/' + row.id" target="_blank">
                        <span class="text-gray-700 text-xs leading-4">{{ row.name }}</span>
                      </router-link>
                      <div class="campaignMetaContainer">
                        <div class="campaignMetaItem gray">{{ campaignChannelDisplayNames[row.channel] }}</div>
                        <div class="campaignMetaItem pink">{{ campaignTypeDisplayNames[row.type] }}</div>
                        <div class="campaignMetaItem blue">{{ campaignStatusDisplayNames[row.status] }}</div>
                      </div>
                    </template>
                    <div class="pt-2 pb-1 text-gray-400 text-sm font-light" v-else>Campaign Deleted</div>
                  </td>
                  <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                    {{ getNumberWithComma(row.sent) }}
                    <div class="text-gray-400" style="font-size: 10px">&nbsp;</div>
                  </td>
                  <!-- <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{{ getNumberWithComma(row.delivered) }}</td> -->
                  <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-400" style="font-size: 12px">
                    <div class="text-gray-500" style="font-size: 14px">{{ getNumberWithComma(row.failed) }}</div>
                    {{ getNumberWithCommaAndDecimal(row.failed_perc) }}%
                  </td>
                  <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-400" style="font-size: 12px">
                    <template v-if="row.channel == 'email'">
                      <div class="text-gray-500" style="font-size: 14px">{{ getNumberWithComma(row.opened) }}</div>
                      {{ getNumberWithCommaAndDecimal(row.opened_perc) }}%
                    </template>
                    <span v-else>
                      N/A
                      <div>&nbsp;</div>
                    </span>
                  </td>
                  <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-400" style="font-size: 12px">
                    <div class="text-gray-500" style="font-size: 14px">{{ getNumberWithComma(row.clicked) }}</div>
                    {{ getNumberWithCommaAndDecimal(row.clicked_perc) }}%
                  </td>
                  <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-400" style="font-size: 12px">
                    <div class="text-gray-500" style="font-size: 14px">{{ getNumberWithComma(row.converted) }}</div>
                    {{ getNumberWithCommaAndDecimal(row.converted_perc) }}%
                  </td>
                  <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{{ $store.state.token.currency }}&nbsp;{{ getNumberWithCommaAndDecimal(row.revenue) }}</td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./campaignOverview.scss"></style>

<script>
import campaignOverviewComponent from './campaignOverviewComponent';
export default campaignOverviewComponent;
</script>
